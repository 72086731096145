import React from "react"
import styled from "styled-components"

const BillingDiv = styled.div`
  margin-bottom: 40px;

  @media screen and (max-width: 960px) {
    margin-bottom: 30px;
  }

  .purple {
    color: #6579f3;
  }

  p {
    color: #6e6d7a;
    margin: 0;
    font-size: 14px;
  }
`

const BillingH3 = styled.h3`
  @media screen and (max-width: 960px) {
    font-size: 18px;
    margin: 24px 0 12px;
  }
  @media screen and (min-width: 961px) {
    margin: 64px 0 12px;
  }
  text-align: left;
  width: 100%;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #192332;
`

function Header({ email, name, title, userID }) {
  return (
    <BillingDiv>
      <BillingH3>
        <span className="purple">
          {email}
          {userID && `(${userID})`}
        </span>{" "}
        / {name}
      </BillingH3>
      <p>{title}</p>
    </BillingDiv>
  )
}

export default Header

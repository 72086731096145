import styled from "styled-components"

const Table = styled.table`
  width: 100%;

  th {
    border-spacing: 0;
    padding-bottom: 8px;
    border-bottom: 1px solid #c9c9ca;
  }

  tbody tr:hover {
    background: #fafafa;
  }

  tr td {
    padding-top: 12px;
    padding-bottom: 12px;
    line-height: 1.3;
  }
`

export default Table

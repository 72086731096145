import React, { useEffect } from "react"
import { get } from "lodash"
import moment from "moment"
import styled from "styled-components"
import ReactTooltip from "react-tooltip"

import SectionHeader from "../pages/settings/components/_SectionHeader"
import Table from "../pages/settings/components/_Table"

import { getSubscriptionFeature as getFeature } from "../store"
// import api from "../utils/api"
// import { toastError } from "../utils/myToasts"

const Container = styled.div`
  margin-bottom: 60px;
  box-sizing: border-box;

  .load-more-button {
    width: 100%;
    box-shadow: 0 0 0 1px hsla(216, 33.3%, 14.7%, 0.1),
      0 1px 3px 0 hsla(216, 12.4%, 39.4%, 0.06);
    line-height: 32px;
    white-space: pre;
    letter-spacing: 0.6px;
    text-decoration: none;
    border: 0;
    margin-top: 4px;
    position: relative;
    cursor: pointer;
    font-weight: 700;
    color: #667af4;
  }

  .gray,
  .green {
    font-weight: 700;
  }

  tbody td:nth-child(1) {
    padding-right: 14px;
  }

  td:nth-child(3),
  th:nth-child(3) {
    @media screen and (max-width: 960px) {
      display: none;
    }
  }

  .url {
    text-decoration: underline;
    cursor: pointer;
  }
`
// const formatCurr = x => Number.parseFloat(x).toFixed(2)

const OrderRow = props => {
  // const subAmount =
  //   get(props, "subscription.subscriptionPlanDetails.amount", 0) / 100
  const proxy = getFeature(get(props, "subscription", {}), "Proxy Type")
  const transactionType = get(props, "transactionType", null)
  const coinfiyOrderNumber = get(props, "descriptions.coinifyOrderNumber", null)
  const coinbaseOrderNumber = get(
    props,
    "descriptions.coinbaseOrderNumber",
    null
  )
  const isCredit = transactionType === "credit"
  const isDebit = transactionType === "debit"
  // const discount = formatCurr(subAmount - props.amount)
  return (
    <tr>
      <td>
        {/* {get(proxy, "servicesOffered", "no service found").split(",")[0]} —{" "}
        {get(proxy, "title", "no title found")} */}
        {/* {get(props, "description", "")}
        <br /> */}
        <span className="small">
          Proxy {get(proxy, "title", "Not Applicable")}
        </span>
        <br />
        <span className="small">
          Order {get(props, "descriptions.orderNumber", "not found")}
        </span>
        <br />
        <span className="small">
          Credit Added ${get(props, "descriptions.creditAdded", "not found")}
        </span>
        <br />
        {coinfiyOrderNumber && (
          <>
            <span className="small">
              Coinify Order Number {coinfiyOrderNumber}
            </span>
            <br />
          </>
        )}
        {coinbaseOrderNumber && (
          <>
            <span className="small">
              Coinbase Order Number {coinbaseOrderNumber}
            </span>
            <br />
          </>
        )}
        <span className="small">
          Source {get(props, "descriptions.source", "not found")}
        </span>
      </td>
      <td align="center">{moment(get(props, "createdAt")).format("LL")}</td>
      <td align="right" className="green">
        {/* ${discount} */}
        {isCredit && `$${get(props, "amountInUsd", 0)}`}
      </td>
      <td align="right">
        <span className="gray">
          {isDebit && `$${get(props, "amountInUsd", 0)}`}
        </span>
        {/* {discount > 0 ? (
          <span>
            <span className="gray">${formatCurr(subAmount)}</span> -{" "}
            <span className="green">${discount}</span> =
            <strong> ${formatCurr(props.amount)}</strong>
          </span>
        ) : (
          <strong>${formatCurr(props.amount)}</strong>
        )} */}
      </td>
      <td align="right">${get(props, "usersBalance", 0)}</td>
    </tr>
  )
}

export default function OrdersList({
  transactions,
  pageCount,
  currentPage,
  fetchTransactions,
}) {
  useEffect(() => {
    fetchTransactions()
  }, [])
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [transactions])

  return (
    <Container>
      <SectionHeader title="Order History" />
      {transactions.length === 0 ? (
        <p>You have no transactions.</p>
      ) : (
        <Table>
          <thead>
            <tr>
              <th className="strong left">Description</th>
              <th className="strong" data-tip="Transaction date">
                Date
              </th>
              <th className="strong right" data-tip="Used Credit">
                Credit
              </th>
              <th
                className="strong right"
                data-tip="Completed Subscription Card Payment"
              >
                Debit
              </th>
              <th className="strong right" data-tip="New credit balance">
                New Credit Balance
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions.map(elem => {
              return <OrderRow key={elem.id} {...elem} />
            })}
          </tbody>
          {currentPage < pageCount ? (
            <tfoot>
              <tr>
                <td colSpan="4s">
                  <button
                    className="load-more-button"
                    onClick={() => fetchTransactions(currentPage + 1)}
                  >
                    load more orders
                  </button>
                </td>
              </tr>
            </tfoot>
          ) : null}
        </Table>
      )}
    </Container>
  )
}

import React from "react"

import { Subscribe } from "unstated"
import TransactionsContainer from "../../stores/transactions"

import OrdersList from "../../components/OrdersList"

import Layout from "./components/_Layout"

function OrderHistory() {
  return (
    <Layout name="Transaction History" title="Review your order history">
      <Subscribe to={[TransactionsContainer]}>
        {txStore => (
          <OrdersList
            fetchTransactions={txStore.fetchTransactions}
            {...txStore.state}
          />
        )}
      </Subscribe>
    </Layout>
  )
}

export default OrderHistory

import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #c9c9ca;
  padding-bottom: 12px;
  margin: 0 0 20px;
`

const SectionTitle = styled.h3`
  text-align: left;
  font-size: 14px;
  line-height: 1.2;
  color: #0b0b0b;
  margin: 0;
`

function SectionHeader({ children, title }) {
  return (
    <Container>
      <SectionTitle children={title} />
      {children}
    </Container>
  )
}

export default SectionHeader

import { Container } from "unstated"

import api from "../utils/api"
import deserialize from "../utils/deserialize"

class TransactionsStore extends Container {
  constructor(props) {
    super(props)
  }

  state = {
    transactions: [],
    pageCount: 0,
    itemCount: 0,
    currentPage: 0,
    limit: 10,
  }

  fetchTransactions = async (pageNo = 1) => {
    try {
      const res = await api.get(
        `/transactions?page=${pageNo}&limit=${this.state.limit}`
      )
      const txs = await deserialize(res.data)
      if (pageNo === 1) {
        this.setState({ transactions: txs })
      } else {
        const concatedTxs = this.state.transactions.concat(txs)
        this.setState({ transactions: concatedTxs })
      }
      this.setState({
        pageCount: res.data.pagination.page_count,
        itemCount: res.data.pagination.item_count,
        currentPage: res.data.pagination.current_page,
      })
    } catch (err) {
      console.error(err)
    }
  }
}

export default TransactionsStore

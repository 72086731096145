import React from "react"
import styled from "styled-components"

import { Link, navigate } from "gatsby"

const SettingsBody = styled.div`
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (min-width: 961px) {
    width: 180px;
  }

  z-index: 0;
  display: block;
  box-sizing: border-box;
`

const SettingsBodyChild = styled.div`
  display: block;
  box-sizing: border-box;

  @media screen and (max-width: 960px) {
    display: none;
  }

  div {
    margin-bottom: 20px;
  }

  a {
    color: #6e6d7a;
    text-decoration: none;
  }

  .active-item {
    color: #0b0b0b;
    font-weight: 700;
  }
`

const PageSelector = styled.select`
  width: 100%;
  padding: 16px;
  background: white;
  border: 1px solid #c9c9ca;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 30px;

  @media screen and (min-width: 961px) {
    display: none;
  }
`

const SidebarItem = props => (
  <div>
    <Link activeClassName="active-item" {...props} />
  </div>
)

const sidebarLinks = [
  { url: "/settings/account", name: "Account" },
  { url: "/settings/notifications", name: "Email notifications" },
  { url: "/settings/billing", name: "Billing & Credit" },
  { url: "/settings/waitlist", name: "Waitlist" },
  { url: "/settings/rockpoints", name: "RockPoints" },
  { url: "/settings/transactions", name: "Transaction History" },
  // {
  //   url: "/settings/cryptomus-recurring-subscriptions",
  //   name: "Cryptomus Recurring Subscriptions",
  // },
  { url: "/settings/manage_subscriptions", name: "Manage Subscriptions" },
]

function Sidebar({ location }) {
  return (
    <SettingsBody>
      <PageSelector
        onChange={e => navigate(e.target.value)}
        value={location.pathname}
      >
        {sidebarLinks.map(s => (
          <option key={s.url} value={s.url} children={s.name} />
        ))}
      </PageSelector>
      <SettingsBodyChild>
        {sidebarLinks.map(s => (
          <SidebarItem key={s.url} to={s.url} children={s.name} />
        ))}
      </SettingsBodyChild>
    </SettingsBody>
  )
}

export default Sidebar
